import { Link } from 'gatsby';
import React, {useEffect}  from 'react';
import Searcher from '../search'
import { connect } from 'react-redux';
import { getDevelopmentsAction } from '../../redux/developmentsDucks';
import {getTotalListFilters} from '../../redux/propertiesDucks'
import Loading from '../Loading'
import VideoBg from "reactjs-videobg";
import mp4 from "../../images/video.mp4";
import Image1 from "../../images/BgMobileWedo.png";

//Helpers
import { getLocation, getLastFeatured } from '../../helpers/helper.developments';
import { getCover } from '../../helpers/helper.rendering';
import { graphql, useStaticQuery } from 'gatsby';

const Home_Main = ({developments,loading,api_key,dispatch}) =>  {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              slogan
              main_image
          }
    }`)

    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction()) // Get all developments
            dispatch(getTotalListFilters()) // Get all developments
        }
    },[api_key])

    const makeLink = (development) => {
        if(development){
            return "/emprendimientos/" + development.id 
        }
    }
    const getSize = () => {
    if (screen.width < 992) 
    {
        return false
    }
    return true
    }

    

    return !loading ? (
        developments?.data?.objects?.length && getLastFeatured(developments.data.objects) ? (
        <div id="home-main" class="banner-main" >
            {getSize() ? 
                <video loop autoPlay muted >
                    <source src={mp4} type="video/mp4" />
                </video>
                :''
            }

            <div class="item d-flex justify-content-center text-center align-items-center">
                <div class="opacity"></div>
                {/* <div class="container" style={{zIndex:"1"}}>
                    <div class="row align-items-center text-center justify-content-center">
                        <div class="col-12 pb-5">
                            <p>
                                {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[0]}
                                <br />
                                {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[1]}
                            </p> 
                            <h1>{developments.data && getLastFeatured(developments.data.objects).name}</h1>
                            <Link to={developments.data && makeLink(getLastFeatured(developments.data.objects))} class="btn btn-primary mb-5">DESCUBRILO</Link>
                        </div>
                    </div>
                </div> */}
            </div>
            <Searcher />
        </div>
        ):
        <div id="home-main" class="banner-main" style={{backgroundImage:'url(' + realEstate?.main_image + ")"}} >
            {getSize() ? 
                <video loop autoPlay muted >
                    <source src={mp4} type="video/mp4" />
                </video>
                :''
            }
            <div class="item d-flex justify-content-center text-center align-items-center">
                <div class="opacity"></div>
                <div class="container" style={{zIndex:"1"}}>
                    <div class="row align-items-center text-center justify-content-center">
                        <div class="col-12 pb-5">
                             <h1>
                                {realEstate?.slogan}
                            </h1> 
                        </div>
                    </div>
                </div>
            </div>
            <Searcher />
        </div>
    ):
    <Loading absolute={true} />
}

export default connect(state => ({
    developments: state.developments.developments,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko
}),null)(Home_Main);